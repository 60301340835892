import React from 'react';
import { translate } from 'react-i18next';

class Breadcrumbs extends React.Component {
  render() {
    return (
      <div>
        <div className={'breadcrumbs'} />
      </div>
    );
  }
}

export default translate('translations-fr')(Breadcrumbs);
