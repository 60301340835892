import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isEmpty } from 'react-redux-firebase';
import { fcmTokens } from '../../helpers/actions/users';
import app from 'firebase/app';
const PUBLIC_KEY =
  'BIuhFW8X8iiRwLdAWnOncUC9e7PfTgISmwwz7WyZDVgXyLDYegNVSPsWuOTqZVeCWJEjyzy1CI5VcJSLY6o-Psg';

class CloudMessaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
    this.messaging = null;
    this.userToken = '';
    this.listenForNewMessages = null;
    this.serviceWorker = null;
  }

  // get firebase messaging service worker
  getFirebaseSW() {
    return new Promise((resolve, reject) => {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(v => {
          if (v.active.scriptURL.search('service-worker.js') >= 0) {
            return resolve(v);
          }
        });
        return reject(Error('Services worker not found'));
      });
    });
  }

  componentDidMount() {
    try {
      if (this.messaging === null) {
        this.messaging = app.messaging();
        this.startListen();
        this.refreshToken();

        this.getFirebaseSW()
          .then(sw => {
            this.messaging.useServiceWorker(sw);

            if (!isEmpty(this.props.user)) {
              // Add the public key generated from the console here.
              this.messaging.usePublicVapidKey(PUBLIC_KEY);
              this.requestPermission();
              this.serviceWorker = sw;
              //sw.addEventListener('message', message => console.log(message));
            }
          })
          .catch(err => console.log(err));
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.messaging = null;
    this.serviceWorker = null;
  }

  requestPermission() {
    // request user permission to send notifications
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        this.updateTokensInServer();
      } else {
        console.log('Unable to get permission to notify.');
        // display notification to request permissions
      }
    });
  }

  // get user device token from firebase
  getMessagingToken() {
    return this.messaging.getToken();
  }

  // send token to server to update list of devices
  updateTokensInServer() {
    this.getMessagingToken()
      .then(token => {
        let deviceId =
          window.navigator.platform +
          '_' +
          window.navigator.appCodeName +
          '_' +
          window.navigator.product;

        this.props
          .dispatch(fcmTokens({ [deviceId]: token }))
          .then(res => {})
          .catch(err => {});

        this.startListen();
      })
      .catch(err => console.log(err));
  }

  startListen() {
    this.messaging.onMessage(payload => {
      //this.pushNotification(payload.data);
    });
  }

  pushNotification(data) {
    const notificationTitle = data.title;
    const notificationOptions = {
      body: data.body,
      icon: data.icon,
      badge: data.badge,
      requireInteraction: true
    };
    this.serviceWorker.showNotification(notificationTitle, notificationOptions);
  }

  refreshToken() {
    this.messaging.onTokenRefresh(() => {
      this.updateTokensInServer();
    });
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => {
  return { user: state.fb.auth, firebase: state.fb };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(CloudMessaging);
