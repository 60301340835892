import React from 'react';
import { translate } from 'react-i18next';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Button from '../CustomButton/CustomButton.jsx';
import Select from 'react-select';

class AccordionCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open:
        this.props.defaultOpened !== undefined ? this.props.defaultOpened : -1
    };
  }

  openCollapse(number) {
    if (this.state.open !== -1) {
      this.refs['collapse' + this.state.open].classList.toggle('expanded');
    }
    this.state.open === number
      ? this.setState({ open: -1 })
      : this.setState({ open: number });
    this.refs['collapse' + number].classList.toggle('expanded');
  }

  render() {
    let { components, plain, opIndex, dOp, changeOrder, total } = this.props;
    let sort = Array.from({ length: total }, (v, i) => i);

    return (
      <div className="card-collapse">
        {components.map((prop, key) => {
          return (
            <Card className={plain ? 'card-plain' : ''} key={key}>
              <CardHeader className={'devis-op-link pl-2 pr-2'}>
                {changeOrder ? (
                  <Select
                    className={['p-1 m-0 mr-2 float-left devis-op-order'].join(
                      ' '
                    )}
                    clearable={false}
                    value={opIndex + 1}
                    options={sort.map(g => ({
                      label: g + 1,
                      value: g + 1
                    }))}
                    onChange={option => changeOrder(option.value - 1, opIndex)}
                  />
                ) : null}
                {/* eslint-disable-next-line */}
                <a
                  data-toggle="collapse"
                  aria-expanded={this.state.open === key ? true : false}
                  className={
                    this.state.open === key ? 'expanded text-left' : 'text-left'
                  }
                  ref={'collapse' + key}
                  onClick={() => this.openCollapse(key)}
                >
                  {prop.title}
                </a>
                {dOp ? (
                  <Button
                    className={'p-1 m-0 ml-2'}
                    color={'danger'}
                    size="sm"
                    onClick={dOp}
                  >
                    <i
                      style={{ color: 'white' }}
                      className="now-ui-icons ui-1_simple-remove"
                    />
                  </Button>
                ) : null}
              </CardHeader>
              <Collapse isOpen={this.state.open === key ? true : false}>
                <CardBody>{prop.text}</CardBody>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}

AccordionCustom.propTypes = {
  // Which one of the component will be opened by default
  defaultOpened: PropTypes.number,
  // The Accordion will have no background color
  plain: PropTypes.bool,
  // example: [{title: "Title of the Collapse", text: "Text/Body of the Collapse"}]
  components: PropTypes.arrayOf(PropTypes.object)
};

export default translate('translations-fr')(AccordionCustom);
