const boxShadow = '0px 0px 5px -1px rgba(0,0,0,0.75)';
const size = 50;
const blue = 'rgb(44, 168, 255)';

export const modal = {
  zIndex: 9999,
  position: 'absolute',
  right: 0,
  bottom: 0,
  padding: 0,
  background: 'rgba(255, 255, 255, 1)',
  boxShadow,
  minWidth: 30,
  height: '100%',
  overflow: 'hidden'
};

export const modalClose = {
  ...modal,
  padding: '5px 10px',
  height: 'auto',
  bottom: '70%',
  background: 'rgba(0, 0, 0, 0.2)',
  borderTopLeftRadius: '5px',
  boxShadow: 'none',
  borderBottomLeftRadius: '5px',
  cursor: 'pointer',
  WebkitTransition: 'background 0.5s ease-in-out',
  MozTransition: 'background 0.5s ease-in-out',
  transition: 'background 0.5s ease-in-out'
};

/* ***** HEADER ***** */
export const header = {
  display: 'inline-block',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: size,
  background: blue
};

export const userInfo = {
  width: '80%',
  minWidth: '80%',
  height: '100%',
  textAlign: 'left',
  float: 'left',
  padding: '5px 10px'
};

export const userPhoto = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundPosition: 'center center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  margin: 'auto auto 0',
  float: 'left',
  marginRight: 10
};

export const userName = {
  height: 40,
  lineHeight: '40px',
  float: 'left'
};

export const userNameText = {
  display: 'inline-block',
  verticalAlign: 'middle',
  lineHeight: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  color: 'white'
};

export const close = {
  width: '20%',
  minWidth: '20%',
  height: '100%',
  textAlign: 'right',
  float: 'right'
};

export const hideButton = {
  color: 'white',
  background: 'blue',
  fontWeight: 'bold',
  border: 'none',
  height: '100%',
  padding: 10
};

/* ***** MESSAGES ***** */

export const messages = {
  marginTop: size,
  wordBreak: 'break-word',
  height: 'calc(100% - 115px)',
  width: '100%',
  overflow: 'hide',
  overflowY: 'scroll',
  padding: 15
};

export const messageContainer = {
  width: '100%',
  display: 'inline-block'
};

export const message = {
  position: 'relative',
  width: 'auto',
  maxWidth: '90%',
  padding: '5px 10px',
  marginBottom: 5,
  borderRadius: 10
};

export const messageLeft = {
  maxWidth: '75%',
  background: 'rgba(44, 168, 255, 0.2)',
  float: 'left',
  borderTopLeftRadius: 0
};

export const messageRight = {
  background: 'rgba(0,0,0,0.2)',
  float: 'right',
  borderBottomRightRadius: 0
};

export const messagePhoto = {
  ...userPhoto,
  backgroundSize: 'cover',
  margin: 0,
  width: 45,
  height: 45
};

export const date = {
  width: '100%'
};

export const text = {
  width: '100%',
  marginBottom: 5,
  wordBreak: 'break-word'
};

export const deleteButton = {
  display: 'none',
  border: 'none',
  position: 'absolute',
  left: '-30px',
  top: 'calc(50% - 10px)'
};

/* ***** FORM ***** */
export const form = {
  display: 'inline-block',
  background: 'white',
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 'auto',
  minHeight: size,
  width: '100%',
  padding: 10,
  borderTop: '2px solid lightgray'
};

export const input = {
  float: 'left',
  width: '88%',
  border: 'none',
  resize: 'none',
  outlineStyle: 'none',
  boxShadow: 'none',
  borderColor: 'transparent',
  borderBottom: '1px solid lightgray'
};

export const sendButton = {
  float: 'right',
  color: 'white',
  background: 'blue',
  fontWeight: 'bold',
  border: 'none',
  width: '12%',
  textAlign: 'right',
  padding: 5
};

export const openButton = {
  ...sendButton,
  width: '100%',
  textAlign: 'center',
  padding: 2
};

export const newMessage = {
  background: 'rgba(0, 0, 0, 0.8)'
};

export const messagesNumber = {
  position: 'absolute',
  left: 0,
  top: 0,
  height: 20,
  width: 20,
  borderRadius: '5px',
  background: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center'
};

export default {
  modal,
  modalClose,
  header,
  userInfo,
  userPhoto,
  userName,
  userNameText,
  close,
  hideButton,
  messages,
  messageContainer,
  message,
  messageLeft,
  messageRight,
  messagePhoto,
  date,
  text,
  form,
  input,
  sendButton,
  openButton,
  deleteButton,
  newMessage,
  messagesNumber
};
