import React, { Component } from 'react';
import styles from './styles';
import { createPortal } from 'react-dom';

class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      message: '',
      open: false,
      lastCheck: Date.now(),
      newMessage: 0
    };
    this.refMessages = React.createRef();
    this.refOpen = React.createRef();
    this.refClose = React.createRef();
    this.refInput = React.createRef();
    this.refSend = React.createRef();
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  componentDidMount() {
    let newState = this.checkNewMessages(
      this.props.messages,
      this.props.lastCheck
    );
    this.setState(
      ps => ({ ...ps, ...newState, open: this.props.open }),
      () => this.scroolMessagesList()
    );
  }

  componentWillReceiveProps({ messages }) {
    let newState = this.checkNewMessages(messages, this.state.lastCheck);
    this.setState(
      ps => ({ ...ps, ...newState }),
      () => this.scroolMessagesList()
    );
  }

  checkNewMessages(messages, lastCheck) {
    let newState = {};
    let cont = 0;

    messages.reverse().forEach(m => {
      if (m.datetime > lastCheck) {
        cont++;
        newState['lastCheck'] = lastCheck;
        newState['newMessage'] = cont;
      } else {
        return;
      }
    });
    messages.reverse();
    return { messages, ...newState };
  }

  scroolMessagesList() {
    if (this.refMessages && this.refMessages.current)
      this.refMessages.current.scroll({
        top: this.refMessages.current.scrollHeight
        //behavior: "smooth"
      });
  }

  sendMessage() {
    let { message } = this.state;
    let m = {
      user: this.props.user.id || '',
      photo: this.props.user.photo || '',
      text: message,
      datetime: Date.now()
    };
    this.setState(ps => ({ ...ps, message: '' }));
    this.props.addMessage(m);
  }

  deleteMessage(index, m) {
    this.props.deleteMessage(index, m);
  }

  openModal() {
    // open chat modal and adjust its width
    this.setState(
      ps => ({
        ...ps,
        modalHeight: 'auto',
        open: true
      }),
      () => this.scroolMessagesList()
    );
  }

  closeModal() {
    // close modal and adjust its dimension to send button
    this.setState(ps => ({
      ...ps,
      open: false,
      newMessage: 0,
      lastCheck: Date.now()
    }));
  }

  displayDeleteButton(index) {
    let buttonActive = document.getElementsByClassName(
      'chat-message-delete-button-active'
    );
    if (buttonActive.length)
      buttonActive[0].classList.remove('chat-message-delete-button-active');

    document
      .getElementById('chat-message-' + index)
      .classList.add('chat-message-delete-button-active');
    document.getElementById('chat-message-' + index).style.display =
      'inline-block';
  }

  hideDeleteButton(index) {
    document
      .getElementById('chat-message-' + index)
      .classList.remove('chat-message-delete-button-active');
    document.getElementById('chat-message-' + index).style.display = 'none';
  }

  createMessage(m, index) {
    let { deleteButton } = this.props;
    let isOwner = m.user && m.user === this.props.user.id;
    // if not is user message owner change display style
    let photo =
      !isOwner && m.photo ? (
        <div
          style={{
            ...styles.messagePhoto,
            backgroundImage: `url('${m.photo}')`
          }}
        ></div>
      ) : null;
    // if is user message owner display delete button
    let buttonDelete = isOwner ? (
      <button
        id={'chat-message-' + index}
        className={'chat-message-delete-button'}
        style={{ ...styles.deleteButton, ...deleteButton.style }}
        onClick={() => this.deleteMessage(index, m)}
      >
        {deleteButton.icon ? deleteButton.icon : 'Delete'}
      </button>
    ) : null;

    let ms =
      !m.user || m.user !== this.props.user.id
        ? styles.messageLeft
        : styles.messageRight;

    return (
      <div
        key={'chat-message-' + m.datetime}
        style={{ ...styles.messageContainer }}
        onClick={() => (isOwner ? this.displayDeleteButton(index) : {})}
        onMouseOver={() => (isOwner ? this.displayDeleteButton(index) : {})}
        onMouseOut={() => (isOwner ? this.hideDeleteButton(index) : {})}
      >
        {photo}
        <div
          key={m.datetime}
          style={{
            ...styles.message,
            ...ms
          }}
        >
          {buttonDelete}
          <span style={{ ...styles.date }}>
            {new Date(m.datetime).toLocaleString('fr-FR')}
          </span>
          <p style={{ ...styles.text }}>{m.text}</p>
        </div>
      </div>
    );
  }

  render() {
    let { message, messages, open, newMessage } = this.state;
    let { sendButton, hideButton, openButton, user } = this.props;

    let mWidth = 'auto';
    if (open) {
      mWidth = window.innerWidth > 768 ? '40%' : '100%';
    }

    // if is close display only open button
    if (!open) {
      return createPortal(
        <div
          style={{ ...styles.modalClose, width: mWidth }}
          onClick={() => this.openModal()}
        >
          {newMessage > 0 ? (
            <div style={{ ...styles.messagesNumber }}>{newMessage}</div>
          ) : null}

          <button
            ref={this.refOpen}
            style={{ ...styles.openButton, ...openButton.style }}
          >
            {openButton.icon ? openButton.icon : 'Open'}
          </button>
        </div>,
        document.getElementById('root')
      );
    }

    // if is open display list of messages, form and close button
    return createPortal(
      <div style={{ ...styles.modal, width: mWidth }}>
        <div style={{ ...styles.header }}>
          <div style={{ ...styles.userInfo }}>
            <div
              style={{
                ...styles.userPhoto,
                backgroundImage: `url('${user.photo}')`
              }}
            ></div>
            <div style={{ ...styles.userName }}>
              <span style={{ ...styles.userNameText }}>{user.name}</span>
            </div>
          </div>
          <div style={{ ...styles.close }}>
            <button
              ref={this.refClose}
              style={{ ...styles.hideButton, ...hideButton.style }}
              onClick={() => this.closeModal()}
            >
              {hideButton.icon ? hideButton.icon : 'Close'}
            </button>
          </div>
        </div>

        <div ref={this.refMessages} style={{ ...styles.messages }}>
          {messages.map((m, i) => this.createMessage(m, i))}
        </div>

        <div style={{ ...styles.form }}>
          <textarea
            ref={this.refInput}
            style={{
              ...styles.input
            }}
            value={message}
            placeholder={'Write your message...'}
            onChange={this.handleChange.bind(this)}
          ></textarea>

          <button
            ref={this.refSend}
            style={{ ...styles.sendButton, ...sendButton.style }}
            onClick={() => this.sendMessage()}
          >
            {sendButton.icon ? sendButton.icon : 'Send'}
          </button>
        </div>
      </div>,
      document.getElementById('root')
    );
  }
}

export default ChatRoom;
